import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import './App.css';
import scan from './logo/scan.jpg';
import w2 from './weixin/w2.jpeg';
import yhwx from './weixin/yhwx.jpg';

class FooterComponent extends React.Component {

    randamWeixin = () => {
        let imgs = [scan, w2,yhwx];
        let index = Math.floor(Math.random() * (9));
        console.log(index);
    
        if (imgs[index]) {
            return imgs[index];
        }
        else {
            return scan;
        }
    }

    render() {
        return (
            <div>
                <Row justify="center">
                    <Col xs={20} sm={{ span: 5, offset: 1 }} style={{ marginBottom: 5 }}>
                        <h4>关于ECLOUD EDUCATION</h4>
                        <p>ECLOUD EDUCATION成立于2015年，作为加拿大IT培训学习平台，我们致力于教学与实践，专职课研团队打造真实企业研发体系，引入一线企业前沿技术，保障课程设计科学、先进，贴合市场需求，帮助每一位学子开启IT职场生涯。</p>
                    </Col>
                    <Col xs={20} sm={{ span: 5, offset: 3 }} style={{ marginBottom: 5 }}>
                        <h4>课程</h4>
                        <p>Web开发旗舰核心课程</p>
                        <p>Web开发入门班</p>
                        <p>Web开发全栈项目班</p>
                        <p>Web开发商业项目实习班</p>
                    </Col>
                    <Col xs={20} sm={{ span: 4 }} style={{ marginBottom: 5 }}>
                        <h4>联系方式</h4>
                        <p>邮箱：info@ecloud.education</p>
                        <p>电话：6479957869</p>
                        <p>地址：2916 Hwy 7, Concord, ON L4K 0K6</p>
                    </Col>
                    <Col xs={20} sm={{ span: 2 }}>
                        <p><img style={{ width: 95, height: 95, marginTop: 10 }} src={scan}></img></p>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default FooterComponent;
