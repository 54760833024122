import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Card, Modal } from 'antd';
import './App.css';
import bookSvg from './logo/book.svg';
import codeSvg from './logo/code.svg';
import currencySvg from './logo/currency.svg';
import dialogSvg from './logo/dialog.svg';
import storyPNG from './logo/chart.jpg';
import eric from './logo/bb.jpg';
import david from './logo/aa.jpg';
import cc from './logo/cc.jpg';
import ann from './logo/dd.jpg';
import contact from './logo/contact.png';
import scan from './logo/scan.jpg';
import ff from './logo/ff.jpg';
import p1 from './logo/p1.JPG';
import p2 from './logo/p2.jpeg';
import p3 from './logo/p3.jpeg';
import p4 from './logo/p4.jpg';
import p5 from './logo/p5.jpg';
import p6 from './logo/p6.png';
import p7 from './logo/p7.jpeg';
import p8 from './logo/p8.jpeg';
import yh from './logo/yh.jpg';

const { Meta } = Card;

class Home extends React.Component {
    state = { visible: false };

    componentDidMount() {
        this.props.setImag(1);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    geLession = (a, b, c, d, e, level, url) => {
        return <div className="jr-program-course">
            <a className="jr-program-course_card" href={url}>
                <div className="jr-program-course_level">
                    <div>
                        <span className="jr-program-course_block  jr-program-course_block_left"></span>
                        <span className={(level < 2) ? "jr-program-course_block jr-program-course_block_center" : "jr-program-course_block jr-program-course_block_center_fill"}></span>
                        <span className={(level < 3) ? "jr-program-course_block jr-program-course_block_right" : "jr-program-course_block jr-program-course_block_right_fill"}></span>
                        <span className="jr-program-course_block_text">{a}</span>
                    </div>
                    <span>{b}</span>
                </div>
                <h4 className="jr-program-course_title">{c}</h4>
                <p className="jr-program-course_description">{d}</p>
                <p className="jr-program-course_coverage">{e}</p>
            </a>
        </div>
    }

    getTeacher = (photoAddress, name) => {
        return <div>
            <img className="profile-image"
                src={photoAddress}
            />
            <h3>{name}</h3>
        </div>

    }

    render() {
        return (

            <div>
                <div>
                    <h2 className="course_title_en">Full Stack Development</h2>
                    <h2 className="course_title_ch">全栈工程师</h2>
                    <h3 className="course_title_sub">加拿大/多伦多/温哥华/蒙特利尔/卡尔加里 IT培训求职全方位服务</h3>
                    <h3 className="course_title_sub">前端后端IT技能培训 | 商业项目实习 | 就业指导 | 推荐信 | 简历修改 | 模拟面试 | 内推机会</h3>
                    <h2>COVID-19期间，我们提供分期付款服务，帮助您无压力学习，还有1V1咨询及试听服务，详情请咨询课程顾问</h2>
                </div>
                <Row justify="center">
                    <Col xs={20} sm={8} offset={1}>
                        {this.geLession('初级', '', 'Web开发旗舰核心课程', '面向零基础编程背景，快速高效的从入门到精通', '课程涵盖: 前端开发、后端开发、项目实习、面向对象设计、算法', 1, '/course/web/software-development')}
                    </Col>
                    <Col xs={20} sm={8} offset={1}>
                        {this.geLession('初级', '', 'Web开发入门班', '面向零基础编程背景，基础知识学习，快速编程入门', '课程涵盖: HTML、CSS、JavaScript、项目实习', 1, '/course/web/entry')}
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={20} sm={8} offset={1}>
                        {this.geLession('中级', '', 'Web开发全栈项目班', '面向已有编程基础，前后端技术学习，项目实习', '课程涵盖: React，Node.js，Redux，项目实习，求职就业全程辅导', 2, '/course/web/full_stack')}
                    </Col>
                    <Col xs={20} sm={8} offset={1}>
                        {this.geLession('高级', '', 'Web开发商业项目实习班', '面向已有编程基础，大量项目开发实习', '课程涵盖: 通过多个真实商业项目实习，提高简历竞争力，轻松拿offer', 3, '/course/web/project')}
                    </Col>
                    {/* <Col xs={20} sm={8} offset={1}>
                        {this.geLession('高级', 'CAD 1950/期', '商业数据分析实战班', '面向零基础编程背景，数据分析师必备技能学习', '课程涵盖: 掌握Python, SQL, Tableau, Big Data', 4, '/course/da')}
                    </Col> */}
                </Row>
                <Row justify="center">
                    <h2 className="course_title_ch">2 + 2模式的系统训练</h2>
                </Row>
                <Row justify="center">
                    <Col xs={20} sm={4} >
                        <div>
                            <div className="icons">
                                <img src={bookSvg} />
                            </div>
                            <div>
                                <h3><span>打好CS理论基础</span></h3>
                            </div>
                        </div>
                    </Col>
                    <Col xs={20} sm={4} >
                        <div>
                            <div className="icons">
                                <img src={codeSvg} />
                            </div>
                            <div>
                                <h3><span>连续性coding实战训练</span></h3>
                            </div>
                        </div>
                    </Col>
                    <Col xs={20} sm={4} >
                        <div>
                            <div className="icons">
                                <img src={dialogSvg} />
                            </div>
                            <div >
                                <h3><span>随堂练习刷题与面试技巧</span></h3>
                            </div>
                        </div>
                    </Col>
                    <Col xs={20} sm={4} >
                        <div>
                            <div className="icons">
                                <img src={currencySvg} />
                            </div>
                            <div>
                                <h3><span>求职实战训练</span></h3>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row justify="center" className="jr-program-course">
                    <Col xs={20} sm={6} offset={1} style={{ marginBottom: 5 }}>
                        <Card title="一级助推：理论体系夯实" >
                            <span>全面掌握算法、数据结构、编程语言，建立系统理论知识体系。</span>
                        </Card>
                    </Col>
                    <Col xs={20} sm={6} offset={1} style={{ marginBottom: 5 }}>
                        <Card title="二级助推：编程动手能力提升" >
                            <span>监督同学以工业界标准完成编程训练,从根本上提升动手能力。<br /></span>
                        </Card>
                    </Col>
                    <Col xs={20} sm={6} offset={1}>
                        <Card title="三级助推：求职面试实战" >
                            <span>简历修改、模拟面试和内部推荐服务，帮助同学锁定offer。</span>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={20}>
                        <h2 className="course_title_ch">为您量身定制的个性化培训方案</h2>
                        <img style={{ width: "100%" }} src={storyPNG} />

                    </Col>
                </Row>
                <Row justify="center">
                    <h2 className="course_title_ch">金牌导师阵容</h2>
                </Row>
                <Row justify="center">
                    <Col xs={20} sm={10} style={{ marginBottom: 5 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(ff, 'Jack')}
                            />
                            <div>
                                <p>全栈工程师 12年编程经验</p>
                                <p>-现任大数据云管理平台资深研发工程师</p>
                                <p>-曾参与日本三菱UFJ银行大型金融系统的设计与研发</p>
                                <p>-精通前端，后端，数据库，云服务，Big Data</p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={20} sm={{ span: 10, offset: 1 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(eric, 'Micheal')}
                            />
                            <div>
                                <p>全栈工程师</p>
                                <p>-曾就职于Amazon，开发维护Alexa核心组件</p>
                                <p>-曾服务于硅谷初创企业及多个大中型企业，担任项目负责人，拥有丰富的运维经验</p>
                                <p>-熟悉各大企业面试流程，精通各类算法</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: 50 }}>
                    <Col xs={20} sm={10} style={{ marginBottom: 5 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(cc, 'Eric')}
                            />
                            <div>
                                <p>全栈工程师</p>
                                <p>-有多年银行项目全栈开发经验包括: TD，MiddleSex，RBC等</p>
                                <p>-曾参与设计开发Costco, Shoppers, Rogers等大型推广运营项目</p>
                                <p>-精通web前端各类框架(Vue, React, Angular)，负责多个项目模型设计及前端架构</p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={20} sm={{ span: 10, offset: 1 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(david, 'David')}
                            />
                            <div>
                                <p>资深面试官+软件开发经理+构架师</p>
                                <p>-面试超过500人,熟悉各大科技公司面试重点,通过对面试者的独到见解提供针对性建议</p>
                                <p>-15年中美加三国知名科技企业开发以及管理经验，深厚中美加科技公司人脉</p>
                                <p>-精通Java，Python，Ruby，Javascript等语言及各种数据库，对主流云服务也有深入了解</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center">
                    <h2 className="course_title_ch">课程顾问</h2>
                </Row>
                <Row justify="center" style={{ marginBottom: 50 }}>
                    <Col xs={20} sm={{ span: 10 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(ann, 'Ann')}
                            />
                            <div>
                                <p>-ECLOUD 加拿大地区市场总负责人</p>
                                <p>-拥有丰富的团队管理经验及课程咨询经验，为人亲和，善于沟通</p>
                                <p>-致力于为学生提供高效耐心的课程咨询服务</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginBottom: 50 }}>
                    <Col xs={20} sm={{ span: 10 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(yh, 'Yuan Hao')}
                            />
                            <div>
                                <p>-加拿大计算机相关专业在读，了解加拿大编程相关专业教育体系</p>
                                <p>-有良好的沟通能力和协调能力，对待客户耐心细致,积极乐观</p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={20} sm={{ span: 10, offset: 1 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(p2, 'Maggie')}
                            />
                            <div>
                                <p>-毕业于加拿大滑铁卢大学，拥有丰富的活动规划项目经验，深谙客户需求</p>
                                <p>-对教育咨询行业有热情，对待学员细心负责</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginBottom: 50 }}>
                    <Col xs={20} sm={{ span: 10 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(p3, 'Hannah')}
                            />
                            <div>
                                <p>-俄勒冈州立大学计算机二学位在读，了解北美计算机相关专业教育体系</p>
                                <p>-拥有耐心，亲和力，并在校园期间多次指导多名学生拿到A+的好成绩</p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={20} sm={{ span: 10, offset: 1 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(p4, 'Henry')}
                            />
                            <div>
                                <p>-西安大略大学engineering本科在读，充分了解加拿大理工科教育体系</p>
                                <p>-富有责任心，能够耐心并高效地解决学员的诸多疑问</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginBottom: 50 }}>
                    <Col xs={20} sm={{ span: 10 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(p5, 'Jane')}
                            />
                            <div>
                                <p>-多伦多大学商科荣誉学士学位，双修经济和统计专业</p>
                                <p>-拥有丰富的市场经验，为人亲和富有耐心</p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={20} sm={{ span: 10, offset: 1 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(p6, '波西')}
                            />
                            <div>
                                <p>-加拿大本地项目管理学士学位</p>
                                <p>-富有耐心，责任心强</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginBottom: 50 }}>
                    <Col xs={20} sm={{ span: 10 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(p7, 'Jacqueline')}
                            />
                            <div>
                                <p>-约克大学商务经济学专业，热爱教育行业</p>
                                <p>-准确理解和把握学员的困惑和问题，引导学员找到自己真正兴趣和擅长的领域</p>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={20} sm={{ span: 10, offset: 1 }}>
                        <Card
                        >
                            <Meta
                                title={this.getTeacher(p8, 'LC')}
                            />
                            <div>
                                <p>-深耕教育行业多年，帮助过大量学生进行未来规划，并最终成功达到目标</p>
                                <p>-能够准确理解和把握学员的困惑和问题，能够和大家分享亲身经历和经验</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <div className="gift_div" onClick={this.showModal}>
                    <img src={contact} className="gift" />
                    <p>课程咨询</p>
                </div>

                <Modal
                    visible={this.state.visible}
                    footer={null}
                    onCancel={() => { this.setState({ visible: false }); }}
                >
                    <div >
                        <h1 style={{ textAlign: "center" }}>课程咨询</h1>
                        <h3 style={{ textAlign: "center" }}>如有任何问题，请扫码添加课程顾问支持您！</h3>
                        <img src={scan} className="scan" />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Home;
