import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { Row, Col } from 'antd';

function ProjectComponent(props) {
    return (
        <div className="courseDetails">
            <Row><h3>{props.title}</h3></Row>
            <Row>
                <Col xs={24} sm={{span:8}}>{props.url}</Col>
                <Col xs={24} sm={{span:15, offset:1}}>{props.description}
                    {
                        props.skills.map(x => <div className="tag">{x}</div>)
                    }
                </Col>
            </Row>
        </div>
    );
}

export default ProjectComponent;