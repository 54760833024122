import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Timeline } from 'antd';
import './App.css';
import ProjectStepComponent from './ProjectStepComponent';

class DataEntryComponent extends React.Component {
    componentDidMount() {
        this.props.setImag(5);
    }

    render() {
        return (
            <div className='course'>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2>商业数据分析实战班</h2>
                            <p>8周课程</p>
                            <p>面向零基础编程背景，数据分析师必备技能学习。课程涵盖: Python, SQL, Tableau, Big Data</p>
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2>课程大纲</h2>
                            <Timeline>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第1周'}
                                        skills={['学习Python的基础知识']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第2周'}
                                        skills={['学习Python的基础知识']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第3周'}
                                        skills={['学习SQL的基础知识']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第4周'}
                                        skills={['学习SQL的基础知识']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第5周'}
                                        skills={['学习Database的基础知识']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第6周'}
                                        skills={['学习Tableau,Data Studio等数据可视化工具的操作']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第7周'}
                                        skills={['学习Tableau,Data Studio等数据可视化工具的操作']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第8周'}
                                        skills={['项目实习']}
                                    />
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default DataEntryComponent;