import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Timeline } from 'antd';
import './App.css';
import ProjectComponent from './ProjectComponent';
import ProjectStepComponent from './ProjectStepComponent';
import shop from './logo/shop.jpg';
import film from './logo/film.jpg';
import blog from './logo/blog.jpg';
import weather from './logo/weather.jpg';

class WebProjectComponent extends React.Component {
    componentDidMount() {
        this.props.setImag(4);
    }

    render() {
        return (

            <div className='course'>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2>Web开发商业项目培训班</h2>
                            <p>4个项目实习经验</p>
                            <p>知识点涵盖前端、后端、数据库。</p>
                            <p>通过大量编程训练，让同学们快速提高编程能力，丰富简历项目内容。</p>
                            <p>积累前端和后端交互的开发经验，掌握全栈开发的基本流程。</p>
                           
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2 >实习项目</h2>
                            <Timeline>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'个人主页'}
                                        url={<img className='course_img' src={blog} />}
                                        description={<p>设计和开发出有着学员自己Style的个人Blog。<br />
                                                    使用HTML和CSS的基础知识，搭建网页架构，美化页面。
                                                    </p>}
                                        skills={['HTML', 'CSS', 'JavaScript']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'天气查询'}
                                        url={<img className='course_img' src={weather} />}
                                        description={<p>使用JavaScript，利用开源API，获得天气数据。<br />
                                                        实现根据城市搜索天气的功能。实践页面交互，数据查询的方法。</p>}
                                        skills={['HTML', 'CSS', 'JavaScript', 'Web API', 'JSON']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'电影查询网站'}
                                        url={<img className='course_img' src={film} />}
                                        description={
                                            <p>开发一个具有查询电影信息，显示推荐列表功能的电影查询网站。<br />
                                               利用第三方API，取得电影数据，深入学习React相关的前端模块和数据交互功能。<br />
                                               本项目将帮助您积累前端开发经验，并且可以在面试中实时Demo。
                                            </p>}
                                        skills={['React', 'Node.js', 'Restful API', 'Database', 'HTML', 'CSS', 'JavaScript']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'开源版Amazon购物网站'}
                                        url={<img className='course_img' src={shop} />}
                                        description={
                                            <p>
                                                开发一个包含购物车，商品查询，订单功能的购物网站。<br />
                                            通过开发后端API，深入学习Node.js相关的后端模块以及数据库的增删改查操作。 <br />
                                            本项目将帮助您积累前端和后端交互的开发经验，掌握全栈开发的基本流程。
                                        </p>}
                                        skills={['React', 'Node.js', 'Restful API', 'Database', 'HTML', 'CSS', 'JavaScript']}
                                    />
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

}

export default WebProjectComponent;
