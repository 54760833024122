import React from 'react';
import 'antd/dist/antd.css';
import { Layout } from 'antd';
import './App.css';
import Home from './Home';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import WebEntryComponent from './WebEntryComponent';
import WebFullStackComponent from './WebFullStackComponent';
import WebProjectComponent from './WebProjectComponent';
import DataEntryComponent from './DataEntryComponent';
import UsComponent from './UsComponent';
import QuestionsComponent from './QuestionsComponent';
import WebALLComponent from './WebALLComponent';

const { Header, Content, Footer } = Layout;

class App extends React.Component {

    setImag = (key) => {

        if (key === 1) {
            document.getElementById('header_id').className = 'header_image';
        }
        else if (key === 2) {
            document.getElementById('header_id').className = 'header_image_one';
        }
        else if (key === 3) {
            document.getElementById('header_id').className = 'header_image_two';
        }
        else if (key === 4) {
            document.getElementById('header_id').className = 'header_image_three';
        }
        else if (key === 5) {
            document.getElementById('header_id').className = 'header_image_four';

        }
    }

    render() {
        return (
            <BrowserRouter>
                <Layout className="App">
                    <Header id="header_id" className="header_image">
                        <HeaderComponent />
                    </Header>
                    <Content >
                        <Switch>
                            <Route path="/" exact
                                render={(routeProps) => (
                                    <Home {...routeProps} setImag={this.setImag} />
                                )} />
                            <Route path="/course/web/entry" exact
                                render={(routeProps) => (
                                    <WebEntryComponent {...routeProps} setImag={this.setImag} />
                                )} />
                            <Route path="/course/web/full_stack" exact
                                render={(routeProps) => (
                                    <WebFullStackComponent {...routeProps} setImag={this.setImag} />
                                )} />
                            <Route path="/course/web/project" exact
                                render={(routeProps) => (
                                    <WebProjectComponent {...routeProps} setImag={this.setImag} />
                                )} />

                            <Route path="/course/da" exact
                                render={(routeProps) => (
                                    <DataEntryComponent {...routeProps} setImag={this.setImag} />
                                )} />
                            <Route path="/course/web/software-development" exact
                                render={(routeProps) => (
                                    <WebALLComponent {...routeProps} setImag={this.setImag} />
                                )} />
                            <Route path="/us" exact
                                render={(routeProps) => (
                                    <UsComponent {...routeProps} setImag={this.setImag} />
                                )} />
                            <Route path="/questions" exact
                                render={(routeProps) => (
                                    <QuestionsComponent {...routeProps} setImag={this.setImag} />
                                )} />
                        </Switch>
                    </Content>
                    <Footer className="footer">
                        <FooterComponent />
                    </Footer>
                </Layout>
            </BrowserRouter>
        );
    }

}

export default App;
