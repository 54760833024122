import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { Row } from 'antd';

function ProjectStepComponent(props) {
    return (
        <div className="courseDetails">
            <Row><h3>{props.title}</h3></Row>
            {
                props.skills.map(x => <Row >{x}</Row>)
            }
        </div>
    );
}

export default ProjectStepComponent;