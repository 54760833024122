import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Card } from 'antd';
import './App.css';
import job from './logo/aboutus.jpg';

class UsComponent extends React.Component {
    componentDidMount() {
        this.props.setImag(1);
    }

    render() {
        return (
            <div>
                <Row justify="center">
                    <h2 className="course_title_ch">ECLOUD将开启一条属于您的IT之路！</h2>
                </Row>
                <Row justify="center">
                    <h2>着重理论实践相结合 我们期待与您一同创造这个时代的明天！</h2>
                </Row>
                <Row justify="center">
                    <Col span={18} >
                        <div>
                            <img className="us_icons" src={job} />
                        </div>
                    </Col>

                </Row>
            </div>
        );
    }
}


export default UsComponent;