import React from 'react';
import 'antd/dist/antd.css';
import { Menu, Row, Col } from 'antd';
import './App.css';
import logo from './logo/lognew2.png';
import { Link } from 'react-router-dom';
const { SubMenu } = Menu;

function HeaderComponent() {
    return (
        <div className="header">
            <Row>
                <Col>
                    <img className="logo" src={logo} />
                </Col>
                <Col span={20}>
                    <Menu theme="light" mode="horizontal" className="header_menu"  >
                        <Menu.Item key="0"><Link to="/"><span>首页</span></Link></Menu.Item>
                        <SubMenu key="1" title="核心课程" >
                            <Menu.Item key="1_0"><Link to="/course/web/software-development">Web开发旗舰核心课程</Link></Menu.Item>
                            <Menu.Item key="1_1"><Link to="/course/web/entry">Web开发入门班</Link></Menu.Item>
                            <Menu.Item key="1_2"><Link to="/course/web/full_stack">Web开发全栈项目班</Link></Menu.Item>
                            <Menu.Item key="1_3"><Link to="/course/web/project">Web开发商业项目实习班</Link></Menu.Item>
                            {/* <Menu.Item key="1_4"><Link to="/course/da">商业数据分析实战班</Link></Menu.Item> */}
                        </SubMenu>
                        <Menu.Item key="4"><Link to="/questions"><span>常见问题</span></Link></Menu.Item>
                        <Menu.Item key="3"><Link to="/us"><span>关于我们</span></Link></Menu.Item>
                    </Menu>
                </Col>
            </Row>
            <h4 className="header_content">加拿大IT西点军校<br />重技能，强实践！<br />4个月开启北美华人IT生涯</h4>
        </div>
    );
}

export default HeaderComponent;
