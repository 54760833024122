import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Timeline } from 'antd';
import './App.css';
import ProjectComponent from './ProjectComponent';
import ProjectStepComponent from './ProjectStepComponent';
import blog from './logo/blog.jpg';
import weather from './logo/weather.jpg';

class WebEntryComponent extends React.Component {

    componentDidMount(){
        this.props.setImag(2);
    }

    render() {
        return (
            <div className='course'>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2>Web开发入门班</h2>
                            <p>2个月课程 + 2个项目实习经验</p>
                            <p>从编程语言开始学习，带您在课上一起写代码、调试，快速积累开发经验，帮助您快速入门<br />
                            学习前端开发语言HTML, CSS和JavaScript的基础知识。建立编程思想以及面向对象的基本概念。<br /></p>
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2 >实习项目</h2>
                            <Timeline>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'个人主页'}
                                        url={<img className='course_img' src={blog} />}
                                        description={<p>设计和开发出有着学员自己Style的个人Blog。<br />
                                                    使用HTML和CSS的基础知识，搭建网页架构，美化页面。
                                                    </p>}
                                        skills={['HTML', 'CSS', 'JavaScript']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'天气查询'}
                                        url={<img className='course_img' src={weather} />}
                                        description={<p>使用JavaScript，利用开源API，获得天气数据。<br />
                                                        实现根据城市搜索天气的功能。实践页面交互，数据查询的方法。</p>}
                                        skills={['HTML', 'CSS', 'JavaScript', 'Web API','JSON']}
                                    />
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2>课程大纲</h2>
                            <Timeline>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第一阶段'}
                                        skills={['学习前端开发语言HTML, CSS和JavaScript的基础知识。建立编程思想以及面向对象的基本概念',
                                                '通过大量编程练习，夯实基础，提高编程能力'
                                                ]}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第二阶段'}
                                        skills={['通过个人主页和天气查询项目的实习，积累开发经验',
                                        '提高编程效率以及解决问题的能力']}
                                    />
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

}

export default WebEntryComponent;