import React from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Timeline } from 'antd';
import './App.css';
import ProjectComponent from './ProjectComponent';
import ProjectStepComponent from './ProjectStepComponent';
import shop from './logo/shop.jpg';
import film from './logo/film.jpg';

export default class WebALLComponent extends React.Component {

    componentDidMount() {
        this.props.setImag(5);
    }

    render() {
        return (
            <div className='course'>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2>Web开发旗舰核心课程</h2>
                            <p>4个月课程 + 2个项目实习经验</p>
                            <p>从编程语言开始学习，带您在课上一起写代码、调试，快速积累开发经验，帮助您快速入门<br />
                            学习前端开发语言HTML, CSS和JavaScript的基础知识。建立编程思想以及面向对象的基本概念。<br />
                            学习React框架的相关知识，在动手实践中积累开发经验，提高开发效率。<br />
                            学习后端开发技术Node.js,掌握和数据库交互的基础知识。
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2 >实习项目</h2>
                            <Timeline>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'电影查询网站'}
                                        url={<img className='course_img' src={film} />}
                                        description={
                                            <p>开发一个具有查询电影信息，显示推荐列表功能的电影查询网站。<br />
                                               利用第三方API，取得电影数据，深入学习React相关的前端模块和数据交互功能。<br />
                                               本项目将帮助您积累前端开发经验，并且可以在面试中实时Demo。
                                            </p>}
                                        skills={['React', 'Node.js', 'Restful API', 'Database', 'HTML', 'CSS', 'JavaScript']}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectComponent
                                        title={'开源版Amazon购物网站'}
                                        url={<img className='course_img' src={shop} />}
                                        description={
                                        <p>
                                            开发一个包含购物车，商品查询，订单功能的购物网站。<br />
                                            通过开发后端API，深入学习Node.js相关的后端模块以及数据库的增删改查操作。 <br /> 
                                            本项目将帮助您积累前端和后端交互的开发经验，掌握全栈开发的基本流程。
                                        </p>}
                                        skills={['React', 'Node.js', 'Restful API', 'Database', 'HTML', 'CSS', 'JavaScript']}
                                    />
                                </Timeline.Item>

                            </Timeline>
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col xs={20} sm={16}>
                        <div class='courseDetails'>
                            <h2>课程大纲</h2>
                            <Timeline>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第一阶段'}
                                        skills={['学习前端开发语言HTML, CSS和JavaScript的基础知识。建立编程思想以及面向对象的基本概念',
                                                '通过大量编程练习，夯实基础，提高编程能力'
                                                ]}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                         title={'第二阶段'}
                                        skills={['学习React框架的相关知识，在动手实践中积累开发经验，提高开发效率',
                                                 '学习React的相关第三方模块，以及在React框架下设计网站的基本方法'
                                                ]}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                         title={'第三阶段'}
                                        skills={['学习后端开发技术Node.js,掌握和数据库交互的基础知识',
                                                '学习数据库查询语言的语法和设计数据库的方法' 
                                                ]}
                                    />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <ProjectStepComponent
                                        title={'第四阶段'}
                                        skills={['通过电影查询网站和购物网站的实习，积累全栈开发的经验',
                                                '实践前后端技术以及数据库的交互操作',
                                                '提高系统设计，解决技术难点的能力'
                                        ]}
                                    />
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

}
