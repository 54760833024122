import React from 'react';
import 'antd/dist/antd.css';
import { Collapse, Row, Col } from 'antd';

const { Panel } = Collapse;

export default class QuestionsComponent extends React.Component {

    componentDidMount() {
        this.props.setImag(1);
    }

    render() {
        return (
            <div className="questions">
                <h1>常见问题</h1>
                <Row justify="center">
                    <Col span={22}>
                        <Collapse bordered={false} defaultActiveKey={['1']}>
                            <Panel className="questions_header" header={"你们的课程设置是什么样的，知识体系学完以后，有包括项目实习吗？有没有职前培训呢？"} key="1">
                                <p>加拿大IT培训ECLOUD EDUCATION的所有课程均由专职导师团队精心打造而成。课程内容扎实，迎合当今IT行业实际需求。项目+技能共同学习的模式，
弥补大家实操经验的短板。整个课程以就业为导向，包含课程+实习项目+简历指导+面试指导+推荐信+终身技能咨询（比如：试用期项目技术遇到问题想咨询，工作学习中遇到技能问题想咨询，换工作跳槽建议指导等）</p>
                            </Panel>
                            <Panel className="questions_header" header={"零基础学编程是指所有人都能学习吗？还是需要具备一定的条件？加拿大编程类招聘对年龄和专业有限制吗？"} key="2">
                                <p>零基础的同学很大一部分的情况是想转行，都是以找工作为导向。零基础是指无编程基础，但是还需要具备以下几点：<br />
                            第一点：基本的英文交流能力，雅思口语6分左右，编程工作对英文要求没有很高，但是基本的日常交流是需要具备的，这样才能顺利通过面试和投入正常的工作中。<br />
                            第二点：良好的逻辑思维能力，注重细节。<br />
                            第三点：对编程不排斥，有好奇心。<br />
                            第四点：认真学习的决心。<br />
                            对于想转行的朋友,一定需要经过多番考虑。Web开发前景好，薪资高。但是如果不具备以上几点，我们真的不建议学。因为就业目的未达到的话，也浪费时间和金钱，一定要先预约免费试听真正去体验。
                            加拿大中小厂对初级程序员招聘需求更加看重的是技能和项目经验，学历要求并没有很多的要求和限制（可自行上网查阅实时的招聘详情就可发现），编程年纪无要求。
                            </p>
                            </Panel>
                            <Panel className="questions_header" header={"我已经有一些编程基础了，怎么去选择课程呢？"} key="3">
                                <p>ECLOUD EDUCATON的IT课程实行分班制，对于有基础的学生会进行技术测试，根据测试水平给予分班建议。 因为大家基础不一样，课程设置和课程目标肯定不一样，
不会一锅端，会把相同等级的同学分配到一个班，保证课程的质量。</p>
                            </Panel>
                            <Panel className="questions_header" header={"我没有尝试过线上教学模式，可以预约试听先感受一下真实的上课环境吗？需要付费吗？如果我报名正式上课以后，发现自己还是不适合学习编程，可以退款吗？"} key="4">
                                <p>ECLOUD EDUCATION 注重学生的教学成果和口碑，旗下的所有课程均推行“先听课，后报名”的政策。所有咨询课程的同学均会先安排免费试听，试听以后再决定是否报名，
除此之外，报名后正式上课的一周以内均可以无条件退款，给予学生充分的冷静期去体验，看自己是否真的合适，看老师授课水平，看课堂互动体验等等。</p>
                            </Panel>
                            <Panel className="questions_header" header={"如果我在蒙特利尔/温哥华/渥太华/卡尔加里/艾德蒙顿/魁北克市/温尼伯/哈密尔顿/伦敦市，想报名IT编程的学习，如果我选择线上授课模式，你们如何保障学习的质量呢？"} key="5">
                                <p>ECLOUD EDUCATION 的所有IT培训课程均追求精和质量。实行3人以内小班制，金牌导师直接一对三，课程均是实时授课，与老师实时互动。线上授课时，老师及学生可以实时看到对方的屏幕，学生需要实时参与编码，
                                老师会实时给予指导。实践证明，这样直接的线上互动授课模式质量高于线下。因为双方关注力均在屏幕实时互动上，正好符合编程教学的模式，一切均在电脑上进行，不追求大班和速度。我们经过实践证明，对于编程学习，
每个人的接受程度是不一样，大班的教学质量不佳，无法保证每一位学生最终的学习效果。</p>
                            </Panel>
                            <Panel className="questions_header" header={"如果我错过课程或者某节课没听懂，可以重听吗？课后作业需要求助的方式是什么？"} key="6">
                                <p>ECLOUD EDUCATION的宗旨是保障学生的教学质量，缺的课时会重新上（请假需提前通知），不懂的内容可以预约老师补课，课后作业是7天/24小时直接与导师联系沟通，保证达成最终的教学目标。</p>
                            </Panel>
                            <Panel className="questions_header" header={"你们的课程会有套路吗，比如开始的课程由金牌导师上课，退款冷静期后换成经验不够丰富的助教上呢？"} key="7">
                                <p>不会，ECLOUD的IT领域课程全程均由官网所示的金牌大牛导师授课，中途不会更换老师或者出现助教，课上课下均是由导师负责，保证学生能直接与老师沟通学习，保证最好的教学质量。</p>
                            </Panel>
                            <Panel className="questions_header" header={"学习完以后包含工作内推吗？需要额外收费吗？"} key="8">
                                <p>包括加拿大本地IT企业内推机会, 无需额外收费。对于零基础的学生, 内推机会主要是一些中小IT公司, 这个符合大家的实际情况, 经过一两年的工作锻炼, 薪资待遇会有很大一个提升。整个培训过程，
                                除了学费以外，不会有任何的附加费用，内推机会均是全职对口编程岗位。大公司的内推机会给予相关专业有基础的同学，包括IBM/AMAZON/ROGERS/RBC/SCOTIABANK等，
中小企业的内推机会给予零基础班级的同学，这样安排比较符合现实的实际情况。</p>
                            </Panel>
                            <Panel className="questions_header" header={"我还是在读学生，有没有内推coop呢？"} key="9">
                                <p>大公司的coop很多是跟学校挂钩，我们很难插手。中小企业我们会努力给同学们争取coop的编程岗位，特此说明一下，这个coop岗位是去IT公司工作并从事编程相关的工作，
                                只有这样这个coop对同学们来说才是有用的，而不是随便去一个公司打杂。我们的培训课程是按照全职程序员的要求去培训，我们也对自己的课程非常有自信。掌握相关的编程知识，不管你是否毕业，
你都有这个能力和实力去找一份编程入门工作，这是我们教学最本质的目的，一切以工作为导向。</p>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>
        );
    }
}
